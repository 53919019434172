<template>
  <div class="container">
    <a-page-header title="修改密码" :backIcon="false"></a-page-header>
    <div class="wrapper">
      <a-form :form="form" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <div style="max-width:600px; margin:0 auto;">
            <a-form-item label="登录账号">
              <a-input :read-only="true" type="text" placeholder="" v-model="userInfo.username" />
            </a-form-item>
            <a-form-item label="手机号码">
              <a-input :read-only="true" type="text" placeholder="" v-model="userInfo.phone" />
            </a-form-item>
            <a-form-item label="登录密码">
              <a-input type="password" placeholder="请输入新密码" v-model="password" />
            </a-form-item>
            <a-form-item label="确认密码">
              <a-input type="password" placeholder="请输入再次新密码" v-model="surePassword" />
            </a-form-item>
            <div class="btn-item">
              <a-popconfirm title="确定要修改密码吗？" placement="topLeft" ok-text="确定" cancel-text="取消" @confirm="handleSubmit">
                <a-button type="primary">修改密码</a-button>
              </a-popconfirm>
            </div>
          </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { userInfo } from '@/utils/api'
export default {
  name: 'AccountInfo',
  data() {
    return {
      userInfo: {},
      labelCol: { lg: { span: 6 }, sm: { span: 6 } },
      wrapperCol: { lg: { span: 16 }, sm: { span: 16 } },
      form: this.$form.createForm(this),
      password: '',
      surePassword: ''
    }
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      userInfo({
        switch: 1
      }).then((res) => {
        this.userInfo = res.info
      })
    },
    handleSubmit() {
      const {password, surePassword} = this
      if (!password) {
        this.$message.error('请输入密码')
        return
      }
      if (!surePassword) {
        this.$message.error('请再次输入密码')
        return
      }
      if (password !== surePassword) {
        this.$message.error('两次输入密码不一致，请重新输入')
        return
      }
      userInfo({
        password,
        switch: 3
      }).then(() => {
        this.$success({
          title: '提示',
          content: '修改成功',
          okText: '确定',
          onOk() {
            
          }
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0 24px 24px;
  input[readonly=true] {
    border: none;
    outline: none;
    cursor: auto;
    &:focus {
      box-shadow: none;
    }
  }
  .btn-item {
    display: flex;
    justify-content: center;
    button {
      width: 50%;
    }
  }
}
</style>